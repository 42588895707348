<template>
  <div>
    <TableFilters :title-count="sourceObject.count" :queries="queries" :loading="isDateLoaded">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to-raw="objects"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
    />
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsObjects from "@/helpers/tableCols/objects";
export default {
  name: "StaffObject",
  data() {
    return {
      loadingBtn: false,
      queries: query.objects.filter(
        el =>
          el.type !== "diller" &&
          el.type !== "city" &&
          el.type !== "service_group__specialist" &&
          el.type !== "service_group"
      )
    };
  },
  components: { TableFilters, TableLayout },
  computed: {
    header() {
      return tableColsObjects.filter(
        el =>
          el.value == "id" ||
          el.value == "type" ||
          el.value == "name" ||
          el.value == "address" ||
          el.value == "controllers_count" ||
          el.value == "users_count" ||
          el.value == "organization" ||
          el.value == "diller"
      );
    },
    sourceObject() {
      return this.$store.getters.getUserObjectsList;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        service_group__specialist: this.urlQuery.id
        // type: this.type
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchStaffObjects").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...this.urlQuery.query,
            service_group__specialist: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchStaffObjects").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadPageObjectsList", this.header).then(() => {
        this.loadingBtn = false;
      });
    }
  }
};
</script>

<style scoped></style>
